<template>
  <el-dialog title="电子保单"
    :visible.sync="dialogVisible"
    custom-class="electronic-policy-dialog"
    width="540px"
    :before-close="handleClose">
    <div v-if="fileObj.url">
      <div class="upload-top">
        <el-upload drag
          accept=".pdf, .jpg, .png, .jpeg"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-change="handlePreview"
          multiple
          :limit="1"
          ref="upload-box"
          :auto-upload="false">
          <div class="el-button el-button--primary el-button--small upload-button">
            <img src="@/assets/images/policy/upload-white.png"
              alt
              srcset />
            重新上传
          </div>
        </el-upload>
        <span>支持格式：.jpg .jpeg .png .pdf ，单个文件不能超过10MB，最多上传1个文件</span>
      </div>
      <div class="electronic-policy-dialog-template electronic-policy-dialog-b">
        <div class="upload-demo">
          <img src="@/assets/images/policy/png.png"
            class="electronic-policy-dialog-b-img"
            alt
            v-if="fileObj.fileType == 'png'"
            srcset />
          <img src="@/assets/images/policy/jpg.png"
            class="electronic-policy-dialog-b-img"
            v-else-if="fileObj.fileType == 'jpg'"
            alt
            srcset />
          <img src="@/assets/images/policy/jpeg.png"
            class="electronic-policy-dialog-b-img"
            v-else-if="fileObj.fileType == 'jpeg'"
            alt
            srcset />
          <img src="@/assets/images/policy/pdf.png"
            class="electronic-policy-dialog-b-img"
            v-else-if="fileObj.fileType == 'pdf'"
            alt
            srcset />
          <i class="el-icon-circle-close"
            @click="handleRemove"></i>
          <div style="text-align: center;">
            {{ fileObj.fileName }}
            <span style="color: #0080FF;text-decoration: underline;margin-left: 5px;cursor: pointer;"
              @click="onPreview">预览</span>
            <span style="color: #0080FF;text-decoration: underline;margin-left: 5px;cursor: pointer;"
              @click="onDownload">下载</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else
      class="add-box">
      <img class="blank-policy"
        src="@/assets/images/policy/blank-policy.png"
        alt
        srcset />
      <div style="text-align: center;color:#333">暂无电子保单</div>
      <el-upload drag
        accept=".pdf, .jpg, .png, .jpeg"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-change="handlePreview"
        multiple
        :limit="1"
        ref="upload-box"
        :auto-upload="false">
        <div class="el-button el-button--primary el-button--small upload-button"
          style="margin: 8px auto 5px;">
          <img src="@/assets/images/policy/upload-white.png"
            alt
            srcset />
          上传文件
        </div>
      </el-upload>
      <div class="types-msg"
        style="text-align: center;">支持格式：.jpg .jpeg .png .pdf ，单个文件不能超过10MB，最多上传1个文件</div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="handleReset">重 置</el-button>
      <el-button type="primary"
        @click="handleImport">确 定</el-button>
    </span>
    <div class>
      <el-image style="width: 0px; height: 0px"
        ref="img-box"
        :preview-src-list="srcList"></el-image>
    </div>
  </el-dialog>
</template>

<script>
import { getPolicyEP, uploadPolicyEP } from "@/api/policy";
import { OSSDirectPass } from "@/api/oss";
export default {
  props: {
    showDialog: Boolean,
    id: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.fileObj = {
            url: ''
          };
          this.fileList = [];
          this.OriginalFileObj = {};
          this.dialogVisible = true;
          this.init();
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      fileObj: {
        url: ''
      },
      OriginalFileObj: {},
      srcList: []
    };
  },
  methods: {
    init() {
      getPolicyEP({
        id: this.id
      }).then(res => {
        this.fileObj = res.data;
        res.data && res.data.url ? this.OriginalFileObj = JSON.parse(JSON.stringify(res.data)) : '';
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    handlePreview(e, r) {
      if (e.size / 1024 / 1024 > 10) {
        this.$message.error('上传文件不能大于10MB');
        this.$refs["upload-box"].clearFiles();
        return false;
      };
      this.fileList = [e];
      this.fileObj = {
        fileName: e.name,
        attachmentType: "EP",
        fileType: e.raw.type.split('/')[1],
        url: URL.createObjectURL(e.raw)
      };
      this.$refs['upload-box'].clearFiles();
    },
    handleRemove() {
      this.fileList = [];
      this.fileObj = {
        url: ''
      };
    },
    handleImport() {
      if (this.OriginalFileObj && this.fileObj && this.OriginalFileObj.url && this.fileObj.url && this.OriginalFileObj.url == this.fileObj.url) {
        this.dialogVisible = false;
        return false;
      }
      if (!this.fileList.length) {
        this.$message.error('请选择文件');
        return false;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      OSSDirectPass(this.fileList[0]).then(pdfUrl => {
        // this.fileObj.url = pdfUrl;
        uploadPolicyEP({
          fileName: this.fileObj.fileName,
          fileType: this.fileObj.fileType,
          policyId: this.id,
          url: pdfUrl
        }).then(res => {
          this.$message.success('电子保单上传成功');
          this.dialogVisible = false;
          loading.close();
        }).catch(err => {
          this.$message.error('电子保单上传失败');
          loading.close();
        });
      });
    },
    handleDownloadTemplate() {
      const link = document.createElement('a'); // 生成一个a标签。
      link.href = '/resource/保单导入模板.xls';
      link.click();
    },
    handleReset() {
      if (this.OriginalFileObj && this.OriginalFileObj.url) {
        this.fileObj = JSON.parse(JSON.stringify(this.OriginalFileObj));
      } else {
        this.fileObj = {
          url: ''
        };
      }
    },
    onPreview() {
      // this.detailData
      if (this.fileObj.fileType == 'pdf') {
        window.open(this.fileObj.url, "_blank");
      } else {
        this.$refs['img-box'].showViewer = true;
        this.srcList = [this.fileObj.url];
      }
    },
    onDownload() {
      if (this.fileObj.fileType == 'pdf') {
        this.pdfDownlad(this.fileObj.url, this.fileObj.fileName);
      } else {
        this.downloadImage(this.fileObj.url, this.fileObj.fileName);
      }
    },
    downloadImage(url, downloadName) {
      const link = document.createElement('a');
      link.setAttribute('download', downloadName);
      const image = new Image();
      // 添加时间戳，防止浏览器缓存图片
      image.src = url + '?timestamp=' + new Date().getTime();
      // 设置 crossOrigin 属性，解决图片跨域报错
      image.setAttribute('crossOrigin', 'Anonymous');
      image.onload = () => {
        link.href = this.getBase64Image(image);
        link.click();
      };
    },
    pdfDownlad(url, fileName) {
      let xhr = new XMLHttpRequest();
      xhr.open('get', url, true);
      xhr.setRequestHeader('Content-Type', `application/pdf`);
      xhr.responseType = 'blob';
      let that = this;
      xhr.onload = function () {
        if (this.status == 200) {
          //接受二进制文件流
          var blob = this.response;
          that.downloadExportFile(blob, fileName);
        }
      };
      xhr.send();
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement('a');
      let href = blob;
      if (typeof blob == 'string') {
        downloadElement.target = '_blank';
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download =
        tagFileName;
      //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != 'string') {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL;
      // return dataURL.replace("data:image/png;base64,", "");
    },
  }
};
</script>

<style lang="scss">
.electronic-policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;

  .el-upload-dragger {
    width: auto;
    height: auto;
    border: none;
    background-color: transparent;
  }
  .el-upload-list {
    display: none;
  }
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    margin-top: 8px;
    &-img {
      display: block;
      width: 70px;
      height: 70px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      position: relative;
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-icon-circle-close {
        position: absolute;
        top: 10px;
        left: calc(50% + 20px);
        cursor: pointer;
        transform: translate(-50%, -50%);
      }
    }
  }
  .upload-button {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    height: 32px;
    width: 98px;
    font-size: 14px;
    img {
      height: 100%;
      margin-right: 6px;
    }
  }
  .upload-top {
    display: flex;
    span {
      margin-left: 8px;
      font-size: 12px;
      font-weight: 300;
      color: #333333;
      line-height: 16px;
    }
  }
  .blank-policy {
    width: 160px;
    height: 120px;
    display: block;
    margin: auto;
  }
  .types-msg {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 300;
    color: #333333;
    line-height: 16px;
  }
  .add-box {
    .el-upload {
      display: block;
    }
  }
}
</style>